const basePath = {
  base: '/',
};

const LandingPath = {
  about: `${basePath.base}about-us`,
  insights: `${basePath.base}insights`,
};

export default {
  ...basePath,
  LandingPath,
};
