// import PropTypes from 'prop-types';
import {
  TEXT_ABOUT_1_TITLE,
  TEXT_ABOUT_1_DESC,
  TEXT_ABOUT_1_SECOND_TITLE,
  TEXT_ABOUT_1_SECOND_DESC,
  TEXT_ABOUT_1_SECOND_DESC2,
  TEXT_ABOUT_1_OUR_CUST,
  TEXT_ABOUT_1_EMPLOYEE,
  TEXT_ABOUT_1_OUR_CUST2,
  TEXT_ABOUT_1_FUNDING,
  TEXT_BTN_CONTROL,
  TEXT_ABOUT_1_TITLE_IDN,
  TEXT_ABOUT_1_DESC_IDN,
  TEXT_ABOUT_1_SECOND_TITLE_IDN,
  TEXT_ABOUT_1_SECOND_DESC_IDN,
  TEXT_ABOUT_1_SECOND_DESC2_IDN,
  TEXT_ABOUT_1_OUR_CUST_IDN,
  TEXT_ABOUT_1_EMPLOYEE_IDN,
  TEXT_ABOUT_1_OUR_CUST2_IDN,
  TEXT_ABOUT_1_FUNDING_IDN,
  TEXT_BTN_CONTROL_IDN,
} from '../../../constants/string';

const displayName = 'SectionHeadContent';
const propTypes = {};
const defaultProps = {};
const textPage = {
  eng: {
    title: TEXT_ABOUT_1_TITLE,
    description: TEXT_ABOUT_1_DESC,
    second_title: TEXT_ABOUT_1_SECOND_TITLE,
    second_desc: TEXT_ABOUT_1_SECOND_DESC,
    second_desc2: TEXT_ABOUT_1_SECOND_DESC2,
    our_coustomer: TEXT_ABOUT_1_OUR_CUST,
    employee: TEXT_ABOUT_1_EMPLOYEE,
    our_customer2: TEXT_ABOUT_1_OUR_CUST2,
    funding: TEXT_ABOUT_1_FUNDING,
    take_control: TEXT_BTN_CONTROL,
  },
  idn: {
    title: TEXT_ABOUT_1_TITLE_IDN,
    description: TEXT_ABOUT_1_DESC_IDN,
    second_title: TEXT_ABOUT_1_SECOND_TITLE_IDN,
    second_desc: TEXT_ABOUT_1_SECOND_DESC_IDN,
    second_desc2: TEXT_ABOUT_1_SECOND_DESC2_IDN,
    our_coustomer: TEXT_ABOUT_1_OUR_CUST_IDN,
    employee: TEXT_ABOUT_1_EMPLOYEE_IDN,
    our_customer2: TEXT_ABOUT_1_OUR_CUST2_IDN,
    funding: TEXT_ABOUT_1_FUNDING_IDN,
    take_control: TEXT_BTN_CONTROL_IDN,
  },
};
export default { displayName, propTypes, defaultProps, textPage };
