// import PropTypes from 'prop-types';
import {
  TEXT_HOME_TITLE_SETUP,
  TEXT_HOME_SUB_SETUP,
  TEXT_HOME_2_SIGN,
  TEXT_HOME_2_SIGN_,
  TEXT_HOME_2_CONNECT,
  TEXT_HOME_2_CONNECT_,
  TEXT_HOME_2_CHECK,
  TEXT_HOME_2_CHECK_,
  TEXT_HOME_2_BENCH,
  TEXT_HOME_2_BENCH_,
  TEXT_HOME_3_CHOOSE_TITLE,
  TEXT_HOME_3_CHOOSE_SUB,
  TEXT_HOME_3_INTEGRATE,
  TEXT_HOME_3_INTEGRATE_,
  TEXT_HOME_3_SAVE,
  TEXT_HOME_3_SAVE_,
  TEXT_HOME_TITLE_SETUP_IDN,
  TEXT_HOME_SUB_SETUP_IDN,
  TEXT_HOME_2_SIGN_IDN,
  TEXT_HOME_2_SIGN__IDN,
  TEXT_HOME_2_CONNECT_IDN,
  TEXT_HOME_2_CONNECT__IDN,
  TEXT_HOME_2_CHECK_IDN,
  TEXT_HOME_2_CHECK__IDN,
  TEXT_HOME_2_BENCH_IDN,
  TEXT_HOME_2_BENCH__IDN,
  TEXT_HOME_3_CHOOSE_TITLE_IDN,
  TEXT_HOME_3_CHOOSE_SUB_IDN,
  TEXT_HOME_3_INTEGRATE_IDN,
  TEXT_HOME_3_INTEGRATE__IDN,
  TEXT_HOME_3_SAVE_IDN,
  TEXT_HOME_3_SAVE__IDN,
} from '../../../constants/string';
const displayName = 'SectionMiddleContent';
const propTypes = {};
const defaultProps = {};
const textPage = {
  eng: {
    setup: TEXT_HOME_TITLE_SETUP,
    setup_sub: TEXT_HOME_SUB_SETUP,
    signup: TEXT_HOME_2_SIGN,
    signup_sub: TEXT_HOME_2_SIGN_,
    connect: TEXT_HOME_2_CONNECT,
    connect_sub: TEXT_HOME_2_CONNECT_,
    check: TEXT_HOME_2_CHECK,
    check_sub: TEXT_HOME_2_CHECK_,
    bench: TEXT_HOME_2_BENCH,
    bench_sub: TEXT_HOME_2_BENCH_,
    choose_thorium: TEXT_HOME_3_CHOOSE_TITLE,
    choose_sub: TEXT_HOME_3_CHOOSE_SUB,
    integrate: TEXT_HOME_3_INTEGRATE,
    integrate_sub: TEXT_HOME_3_INTEGRATE_,
    saved: TEXT_HOME_3_SAVE,
    saved_sub: TEXT_HOME_3_SAVE_,
  },
  idn: {
    setup: TEXT_HOME_TITLE_SETUP_IDN,
    setup_sub: TEXT_HOME_SUB_SETUP_IDN,
    signup: TEXT_HOME_2_SIGN_IDN,
    signup_sub: TEXT_HOME_2_SIGN__IDN,
    connect: TEXT_HOME_2_CONNECT_IDN,
    connect_sub: TEXT_HOME_2_CONNECT__IDN,
    check: TEXT_HOME_2_CHECK_IDN,
    check_sub: TEXT_HOME_2_CHECK__IDN,
    bench: TEXT_HOME_2_BENCH_IDN,
    bench_sub: TEXT_HOME_2_BENCH__IDN,
    choose_thorium: TEXT_HOME_3_CHOOSE_TITLE_IDN,
    choose_sub: TEXT_HOME_3_CHOOSE_SUB_IDN,
    integrate: TEXT_HOME_3_INTEGRATE_IDN,
    integrate_sub: TEXT_HOME_3_INTEGRATE__IDN,
    saved: TEXT_HOME_3_SAVE_IDN,
    saved_sub: TEXT_HOME_3_SAVE__IDN,
  },
};
export default { displayName, propTypes, defaultProps, textPage };
