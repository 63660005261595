/* eslint-disable prettier/prettier */
export const TEXT_MENU_HOME = 'Home';
export const TEXT_MENU_ABOUT = 'About Us';
export const TEXT_MENU_INSIGHTS = 'Insights';
export const TEXT_BTN_SIGNIN = 'Sign in';
export const TEXT_BTN_JOIN = 'Join';

export const TEXT_MENU_HOME_IDN = 'Beranda';
export const TEXT_MENU_ABOUT_IDN = 'Tentang Kami';
export const TEXT_MENU_INSIGHTS_IDN = 'Wawasan';
export const TEXT_BTN_SIGNIN_IDN = 'Masuk';
export const TEXT_BTN_JOIN_IDN = 'Daftar';

export const TEXT_BTN_CONTROL = 'Take control now';
export const TEXT_BTN_CONTROL_IDN = 'Coba Sekarang';

export const TEXT_HOME_TITLE = 'THE CONTROL CENTRE OF YOUR BUSINESS';
export const TEXT_HOME_DESC = 'Keep your business in check, know the numbers, gain valuable insights, and always be ready to take action. All in one lightweight (and free!) platform.';
export const TEXT_HOME_TITLE_STUDY = 'OUR CASE STUDY';
export const TEXT_HOME_TITLE_SETUP = '60-second setup';
export const TEXT_HOME_SUB_SETUP = 'Hold your breath. The control centre will be ready before you run out of air.';
export const TEXT_HOME_2_SIGN = 'Sign Up';
export const TEXT_HOME_2_SIGN_ = 'Join the community of brands, business owners, and startups. The more the merrie!';
export const TEXT_HOME_2_CONNECT = 'Connect';
export const TEXT_HOME_2_CONNECT_ = 'Connect all tools and channels to Thorium. From marketplace to websites, all are welcome!';
export const TEXT_HOME_2_CHECK = 'Check';
export const TEXT_HOME_2_CHECK_ = 'All your data will be shown under a single dashboard. No more jumping from one tab to another.';
export const TEXT_HOME_2_BENCH = 'Benchmark';
export const TEXT_HOME_2_BENCH_ = 'Compare your business with Industry trends and be ready to follow or change them.';
export const TEXT_HOME_3_CHOOSE_TITLE = 'Why choose Thorium?';
export const TEXT_HOME_3_CHOOSE_SUB = 'You’ll never want to go back to the old ways again.';
export const TEXT_HOME_3_INTEGRATE = 'Integrate with all your platform';
export const TEXT_HOME_3_INTEGRATE_ = 'No code, no pixel. Thorium seamless integration combines all marketplace, website, ads and analytic dashboards into a single control centre.';
export const TEXT_HOME_3_SAVE = 'Save time and effort';
export const TEXT_HOME_3_SAVE_ = 'Sometimes you don’t even know your own business, because there’s too much data scattered in too many platforms. Thorium presents all critical numbers in friendly display, helping you to understand what’s happening within a glance.';
export const TEXT_HOME_4_TITLE = 'Benchmark VS Industry';
export const TEXT_HOME_4_SUB = 'Sharing you the modern superpower; latest data and information';
export const TEXT_HOME_4_DESC = 'Get a clear, quick, and precise information on things that matters to your business';
export const TEXT_HOME_4_APPAREL = 'Apparel and Footwear';
export const TEXT_HOME_4_BEAUTY = 'Beauty and Personal Care';
export const TEXT_HOME_4_CONSUM = 'Consumer Electronics';
export const TEXT_HOME_4_FNB = 'Foods and Beverages';
export const TEXT_HOME_4_FINANCE = 'Financial Services';
export const TEXT_HOME_4_GARDEN = 'Home, Garden and Kitchen';
export const TEXT_HOME_4_JEWEL = 'Jewellery and Accessories';
export const TEXT_HOME_4_MED = 'Medical and Healthcare';
export const TEXT_HOME_4_OTHERS = 'Others Industry';
export const TEXT_HOME_4_CERT = 'Our certificated partner';
export const TEXT_HOME_4_TESTI = 'Thorium Testimonials';

export const TEXT_HOME_TITLE_IDN = 'KELOLA BISNISMU DALAM SATU PLATFORM';
export const TEXT_HOME_DESC_IDN = 'Mencari data, berburu insights, dan ambil keputusan bisnis; semua bisa kamu lakukan dengan cepat dan mudah dengan Thorium. ';
export const TEXT_HOME_TITLE_STUDY_IDN = 'STUDI KASUS KAMI';
export const TEXT_HOME_TITLE_SETUP_IDN = 'Set, Sat, Set, Insight Didapet';
export const TEXT_HOME_SUB_SETUP_IDN = 'Kelola data yang ada tanpa makan waktu lama';
export const TEXT_HOME_2_SIGN_IDN = 'Sign Up';
export const TEXT_HOME_2_SIGN__IDN = 'Gabung dengan brand, business owner, dan startup lainnya. Makin rame, makin seru!';
export const TEXT_HOME_2_CONNECT_IDN = 'Connect';
export const TEXT_HOME_2_CONNECT__IDN = 'Sambungkan tools dan channel bisnismu. Dari marketplace sampai website; semuanya bisa!';
export const TEXT_HOME_2_CHECK_IDN = 'Check';
export const TEXT_HOME_2_CHECK__IDN = 'Semua data ditampilkan dalam satu dashboard. Lebih cepat, lebih gampang.';
export const TEXT_HOME_2_BENCH_IDN = 'Benchmark';
export const TEXT_HOME_2_BENCH__IDN = 'Bandingkan bisnismu dengan tren di industri sejenis biar nggak ketinggalan zaman.';
export const TEXT_HOME_3_CHOOSE_TITLE_IDN = 'Kenapa Harus Thorium?';
export const TEXT_HOME_3_CHOOSE_SUB_IDN = 'Platform sakti untuk pengelolaan data bisnismu';
export const TEXT_HOME_3_INTEGRATE_IDN = 'Nyambung dengan banyak platform';
export const TEXT_HOME_3_INTEGRATE__IDN = 'Data dari marketplace, web, iklan, dan tools lainnya terkumpul di satu tempat; bisa terwujud kalau kamu pakai Thorium!';
export const TEXT_HOME_3_SAVE_IDN = 'Hemat Waktu & Tenaga';
export const TEXT_HOME_3_SAVE__IDN = 'Sudah waktunya kamu berpisah dengan malam-malam lembur! Dengan Thorium, semua data penting ditampilkan dengan rapi & sederhana biar kamu bisa lebih mudah memahami kondisi bisnismu. ';
export const TEXT_HOME_4_TITLE_IDN = 'Industry Benchmark';
export const TEXT_HOME_4_SUB_IDN = 'Lebih dekat dengan tren dan informasi terkini tentang industri sejenis';
export const TEXT_HOME_4_DESC_IDN = 'Udah nggak zaman ambil keputusan pakai cap-cip-cup! Thorium bisa bantu kamu mengambil keputusan yang tepat dengan data tren dari industri yang sejenis dengan bisnismu.';
export const TEXT_HOME_4_APPAREL_IDN = 'Fashion';
export const TEXT_HOME_4_BEAUTY_IDN = 'Perawatan & Kecantikan';
export const TEXT_HOME_4_CONSUM_IDN = 'Elektronik';
export const TEXT_HOME_4_FNB_IDN = 'Makanan & Minuman';
export const TEXT_HOME_4_FINANCE_IDN = 'Keuangan';
export const TEXT_HOME_4_GARDEN_IDN = 'Rumah Tangga & Dapur';
export const TEXT_HOME_4_JEWEL_IDN = 'Aksesoris & Perhiasan';
export const TEXT_HOME_4_MED_IDN = 'Kesehatan';
export const TEXT_HOME_4_OTHERS_IDN = 'Industri Lainnya';
export const TEXT_HOME_4_CERT_IDN = 'Partner Kami';
export const TEXT_HOME_4_TESTI_IDN = 'Testimoni';

export const TEXT_ABOUT_1_TITLE = 'SIMPLIFYING BUSINESS PROCESS';
export const TEXT_ABOUT_1_DESC = 'How can you control your business, if you can’t check and evaluate sales data? We give you the one tool to rule them all. With Thorium, you can save time, stay informed, plan better, and make the best decisions.';
export const TEXT_ABOUT_1_SECOND_TITLE = 'Powered by seasoned players, professionals, and consultants.';
export const TEXT_ABOUT_1_SECOND_DESC = 'The story began in 2017, when one of Indonesia’s top advertising agency, bolehdicoba.com, started to build, improve, and sharpen their own “battle tool” to serve major fashion brands in Indonesia.';
export const TEXT_ABOUT_1_SECOND_DESC2 = 'Now they release the tool as “Thorium”; the metal of Thor, so everyone can have one reliable dashboard as the base of all digital marketing activities.';
export const TEXT_ABOUT_1_OUR_CUST = 'Our Customers, from around the world';
export const TEXT_ABOUT_1_EMPLOYEE = 'Employee, NPS Score';
export const TEXT_ABOUT_1_OUR_CUST2 = 'Our Customers, would recommend us to a friend';
export const TEXT_ABOUT_1_FUNDING = 'Total funding, raised from top VCs';
export const TEXT_ABOUT_2_TITLE = 'Topnotch features';
export const TEXT_ABOUT_2_SUBTITLE = 'Business-changing features at your disposal (more to come!)';
export const TEXT_ABOUT_2_COMBINED_SALES = 'Combined sales channels metrics';
export const TEXT_ABOUT_2_COMBINED_SALES_ = 'Collect and compare sales informations from all the marketplaces in South East Asia';
export const TEXT_ABOUT_2_INDUSTRY = 'Industry data benchmark';
export const TEXT_ABOUT_2_INDUSTRY_ = 'Safely and securely compare your business performance with Industry data and trends.';
export const TEXT_ABOUT_2_INTEGRATION = 'Easy intergration with sales tools ';
export const TEXT_ABOUT_2_INTEGRATION_ = 'Integrates easily with websites (shopify and woocommerce), analytics, and digital advertising tools.';
export const TEXT_ABOUT_2_REALTIME = 'Real-time performance updates';
export const TEXT_ABOUT_2_REALTIME_ = 'Always know your Customer Acquisition Cost, revenues by channels, ROAS, and other actionable digital informations';
export const TEXT_ABOUT_2_CASESTUDY = 'Our case study';
export const TEXT_ABOUT_2_CASESTUDY_ = 'Thorium is battle tested by multiple direct to consumer (D2C) brands and eCommerce businesses who relies on online channels to build presence, acquire customers, and generate sustainable revenues.';
export const TEXT_ABOUT_3_FOUNDER = 'From founders to founders';
export const TEXT_ABOUT_3_FOUNDER_SUB = 'We know your problems because we are you. And we’re thrilled to share our weapon.';
export const TEXT_ABOUT_3_FOUNDER_DESC = 'Building an eCommerce busines takes hard work, determination and perseverance. More than anything, it takes ambition. As brand owners ourselves, Thorium understands these ambitions and we want to support them. That’s why we simplify digital marketing and sales process so you can take quick actions and make better decisions.';
export const TEXT_ABOUT_3_MEET_TEAM = 'Meet our teams';
export const TEXT_ABOUT_3_MEET_TEAM_DESC = 'We are here to take care your problems.';

export const TEXT_ABOUT_1_TITLE_IDN = 'Bantu Proses Bisnismu Jadi Lebih Simpel';
export const TEXT_ABOUT_1_DESC_IDN = 'Yakin bisa kontrol bisnis dengan baik kalau data bisnismu masih berceceran? THORIUM bisa jadi tools yang tepat untuk memudahkan pengecekan data bisnis agar pengambilan keputusan jadi lebih cepat dan tepat.';
export const TEXT_ABOUT_1_SECOND_TITLE_IDN = 'Dikembangkan oleh sesama brand owner';
export const TEXT_ABOUT_1_SECOND_DESC_IDN = 'Satu hari di tahun 2017, salah satu agensi iklan top Indonesia, bolehdicoba.com, terdorong untuk mengembangkan “senjata” baru untuk memenuhi ekspektasi brand fashion kenamaan di Indonesia.';
export const TEXT_ABOUT_1_SECOND_DESC2_IDN = 'Sekarang, senjata itu disempurnakan menjadi THORIUM; logam Thor, sehingga setiap bisnis dapat memiliki satu dashboard serbaguna sebagai basis dari semua aktivitas pemasaran digital.';
export const TEXT_ABOUT_1_OUR_CUST_IDN = 'Pengguna dari berbagai wilayah di dunia';
export const TEXT_ABOUT_1_EMPLOYEE_IDN = 'Karyawan NPS Score';
export const TEXT_ABOUT_1_OUR_CUST2_IDN = 'Pengguna kami senang dengan apa yang didapat';
export const TEXT_ABOUT_1_FUNDING_IDN = 'Pendanaan yang terkumpul dari VC ternama';
export const TEXT_ABOUT_2_TITLE_IDN = 'Fitur-Fitur Thorium';
export const TEXT_ABOUT_2_SUBTITLE_IDN = 'Tersedia banyak fitur keren untuk membantu bisnismu';
export const TEXT_ABOUT_2_COMBINED_SALES_IDN = 'Metrik Penjualan Lebih Terintegrasi';
export const TEXT_ABOUT_2_COMBINED_SALES__IDN = 'Kumpulkan informasi penjualan dari semua marketplace di Asia Tenggara';
export const TEXT_ABOUT_2_INDUSTRY_IDN = 'Lihat tren dan data industri';
export const TEXT_ABOUT_2_INDUSTRY__IDN = 'Cek dan bandingkan performa bisnismu dengan data dan tren Industri sejenis';
export const TEXT_ABOUT_2_INTEGRATION_IDN = 'Mudah diintegrasikan dengan tools penjualan';
export const TEXT_ABOUT_2_INTEGRATION__IDN = 'Dari marketplace, iklan, sampai website; semuanya bisa kamu kelola dalam satu platform.';
export const TEXT_ABOUT_2_REALTIME_IDN = 'Pantau performa bisnis secara real-time';
export const TEXT_ABOUT_2_REALTIME__IDN = 'Lebih mudah dan cepat dalam ambil keputusan karena data bisnismu selalu update';
export const TEXT_ABOUT_2_CASESTUDY_IDN = 'Studi Kasus Kami';
export const TEXT_ABOUT_2_CASESTUDY__IDN = 'Thorium telah digunakan oleh berbagai brand direct-to-consumer (D2C) dan eCommerce untuk membangun presence, memahami konsumen, dan menghasilkan pendapatan secara konstan.';
export const TEXT_ABOUT_3_FOUNDER_IDN = 'Dari founders untuk founders';
export const TEXT_ABOUT_3_FOUNDER_SUB_IDN = 'Kami paham situasinya. Karena itu kami senang berbagi “senjata” yang berguna untuk bisnismu.';
export const TEXT_ABOUT_3_FOUNDER_DESC_IDN = 'Membangun bisnis eCommerce butuh tekad, ambisi, dan usaha keras. Thorium memahami kondisi ini dan ingin ikut membantu.  Itu sebabnya kami menyederhanakan proses marketing dan penjualan digital agar bisnismu dapat mengambil tindakan cepat dan membuat keputusan yang lebih baik.';
export const TEXT_ABOUT_3_MEET_TEAM_IDN = 'Orang-Orang di Balik Layar';
export const TEXT_ABOUT_3_MEET_TEAM_DESC_IDN = 'Kami di sini untuk membantu kamu';

export const TEXT_INSIGHTS_1_TITLE = 'YOU’LL ALWAYS KNOW THE NUMBERS';
export const TEXT_INSIGHTS_1_DESC = 'THORIUM::integrates, simplifies, and crunches digital marketing and sales data, allowing business owners to make quick and informed decisions.';
export const TEXT_INSIGHTS_2_SUM_DATA = 'Summarized data';
export const TEXT_INSIGHTS_2_SUM_DATA_ = 'If you wonder how’s the growth of your ROAS, CPR, AOV, and other metrics, just take a peek at your Thorium Dashboard.';
export const TEXT_INSIGHTS_2_SALES_PERF = 'Sales Performance';
export const TEXT_INSIGHTS_2_SALES_PERF_ = 'Easily evaluate your sales performance. From your top product sales to amount of items sold, you can check it anywhere, anytime.';
export const TEXT_INSIGHTS_2_CAMP_PERF = 'Campaign performance';
export const TEXT_INSIGHTS_2_CAMP_PERF_ = 'Quickly check your ads performance. Always stay up-to-date with your engagement, link clicks, CPM, CPC, and other metrics.';
export const TEXT_INSIGHTS_3_REALTIME = 'Real time comparison';
export const TEXT_INSIGHTS_3_REALTIME_SUB = 'Compare your business with industry data and know your position in the game.';
export const TEXT_INSIGHTS_3_REALTIME_DESC = 'Thorium compares your CPA, CPR, and other metrics to the specific industry so you can assess your overall performance, then take quick and calculated actions.';
export const TEXT_INSIGHTS_4_INSIGHTS = 'Insightful Dashboard';
export const TEXT_INSIGHTS_4_INSIGHTS_ = 'Use the power of data to build and refine strategies and gain advantage over your competitor. Because the best part of a competition is when you’re winning it ;D';
export const TEXT_INSIGHTS_5_ALLIN = 'All-in-one dashboard';
export const TEXT_INSIGHTS_5_ALLIN_ = 'THORIUM::seamlessly intergrates most marketplace, analytics, and advertising platforms in South East Asia. Tapping the market has never been so easy.';

export const TEXT_INSIGHTS_1_TITLE_IDN = 'SELALU TAHU PERSIS DATA BISNIS';
export const TEXT_INSIGHTS_1_DESC_IDN = 'THORIUM membantu founder mengambil keputusan cepat dan tepat karana data yang ditampilkan sudah terintegrasi, terolah, dan dengan tampilan yang simpel.';
export const TEXT_INSIGHTS_2_SUM_DATA_IDN = 'Meringkas Data';
export const TEXT_INSIGHTS_2_SUM_DATA__IDN = 'Mau tahu perkembangan ROAS, CPR, AOV, dan metrik pemasaran lainnya? Bisa ngintip dari dashboard Thorium!';
export const TEXT_INSIGHTS_2_SALES_PERF_IDN = 'Performa Penjualan';
export const TEXT_INSIGHTS_2_SALES_PERF__IDN = 'Dengan Thorium, kamu bisa memeriksa data penjualanmu secara menyeluruh; kapan saja dan di mana saja.';
export const TEXT_INSIGHTS_2_CAMP_PERF_IDN = 'Performa Campaign';
export const TEXT_INSIGHTS_2_CAMP_PERF__IDN = 'Pantau terus performa iklan digitalmu. Dari engagement, clicks, CPM, CPC, dan metrik lainnya; semua bisa!';
export const TEXT_INSIGHTS_3_REALTIME_IDN = 'Perbandingan Real-time';
export const TEXT_INSIGHTS_3_REALTIME_SUB_IDN = 'Dapatkan data dan tren industri ter-update yang penting untuk pengembangan strategi selanjutnya.';
export const TEXT_INSIGHTS_3_REALTIME_DESC_IDN = 'Thorium membandingkan CPA, CPR, dan metrik lainnya dengan industri tertentu agar kamu bisa menilai kinerja dan mengambil keputusan bisnis dengan cepat dan tepat.';
export const TEXT_INSIGHTS_4_INSIGHTS_IDN = 'Dashboard yang Insightful';
export const TEXT_INSIGHTS_4_INSIGHTS__IDN = 'Bagian terbaik dari sebuah kompetisi adalah ketika kamu bisa menang! Gunakan data untuk menyempurnakan strategi agar bisnismu lebih unggul dari kompetitor.';
export const TEXT_INSIGHTS_5_ALLIN_IDN = 'All-in-one dashboard';
export const TEXT_INSIGHTS_5_ALLIN__IDN = 'THORIUM mengintegrasikan sebagian besar platform marketing, analytics, dan periklanan di Asia Tenggara. Menguasai pasar bukan lagi mimpi siang bolong!';

export const TEXT_FOOT_CONTACT = 'Contact Us';
export const TEXT_FOOT_ADDRESS = 'ADDRESS';
export const TEXT_FOOT_CONTACTTME = 'Send us a message';
export const TEXT_FOOT_FULLNAME = 'Full Name';
export const TEXT_FOOT_EMAIL = 'Email';
export const TEXT_FOOT_SUBJECT = 'Subject';
export const TEXT_FOOT_MESSAGE = 'Your Message';
export const TEXT_FOOT_SEND = 'Send';

export const TEXT_FOOT_CONTACT_IDN = 'Kontak';
export const TEXT_FOOT_ADDRESS_IDN = 'Alamat';
export const TEXT_FOOT_CONTACTTME_IDN = 'Hubungi Kami';
export const TEXT_FOOT_FULLNAME_IDN = 'Nama Lengkap';
export const TEXT_FOOT_EMAIL_IDN = 'Email';
export const TEXT_FOOT_SUBJECT_IDN = 'Subjek';
export const TEXT_FOOT_MESSAGE_IDN = 'Pesan';
export const TEXT_FOOT_SEND_IDN = 'Kirim';
