import React from 'react';

import { Layout } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';

import { HeaderMenuLanding, FooterLanding } from '../../components/Layout';
import { LANDING_ROUTE } from '../../navigation/landing.route';

import config from './Landing.component.config';

class LandingPage extends React.PureComponent {
  render() {
    const { location, history, setupLang } = this.props;

    return (
      <Layout id="dashboard">
        <HeaderMenuLanding
          location={location}
          history={history}
          setupLang={setupLang}
        />
        <Switch>
          {LANDING_ROUTE.map((route) => (
            <Route key={route.id} {...route} />
          ))}
          <Redirect to="/" />
        </Switch>

        <FooterLanding />
      </Layout>
    );
  }
}

LandingPage.displayName = config.displayName;
LandingPage.defaultProps = config.defaultProps;
LandingPage.propTypes = config.propTypes;

export default LandingPage;
