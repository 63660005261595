import React from 'react';
import { Col, Image, Row, Typography, Carousel, Avatar, Button } from 'antd';
import {
  HOME_BREAK,
  HOME_FBMP,
  HOME_GOMP,
  HOME_SHOPIFYMP,
  HOME_TIKTOKMP,
} from '../../../assets/images/png';
import { ICON_THORIUM } from '../../../assets/icons';

import config from './SectionBottomContent.component.config';
import './SectionBottomContent.component.style.less';
import './SectionBottomContent.component.style.responsive.less';

const { Title, Text } = Typography;
const SectionBottomContent = ({ ...props }) => {
  const lang = props.lang.toLowerCase();
  const wording = config.textPage[lang];
  let carousel = React.createRef();
  const propsCarousel = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
  };
  const nextCarousel = () => {
    carousel.next();
  };
  const prevCarousel = () => {
    carousel.prev();
  };
  return (
    <div id="SectionBottomContent">
      <div className="content-head">
        <Row gutter={60}>
          <Col xs={24} md={12} xl={12}>
            <div className="content-title">
              <Title level={1}>{wording.title}</Title>
              <Title level={3}>{wording.subtitle}</Title>
              <Text className="description">{wording.description}</Text>
            </div>
          </Col>
          <Col xs={24} md={12} xl={12}>
            <Row gutter={20}>
              <Col xs={12} md={8} xl={8}>
                <div className="box">
                  <div>
                    <i className="icon-shirt" />
                  </div>
                  <Text className="benchmark-industry">
                    <b>{wording.apparel}</b>
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={8} xl={8}>
                <div className="box">
                  <div>
                    <i className="icon-body-care" />
                  </div>
                  <Text className="benchmark-industry">
                    <b>{wording.beauty}</b>
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={8} xl={8}>
                <div className="box">
                  <div>
                    <i className="icon-electronic" />
                  </div>
                  <Text className="benchmark-industry">
                    <b>{wording.consumer}</b>
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={8} xl={8}>
                <div className="box">
                  <div>
                    <i className="icon-food" />
                  </div>
                  <Text className="benchmark-industry">
                    <b>{wording.fnb}</b>
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={8} xl={8}>
                <div className="box">
                  <div>
                    <i className="icon-bill" />
                  </div>
                  <Text className="benchmark-industry">
                    <b>{wording.finance}</b>
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={8} xl={8}>
                <div className="box">
                  <div>
                    <i className="icon-house-modern" />
                  </div>
                  <Text className="benchmark-industry">
                    <b>{wording.garden}</b>
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={8} xl={8}>
                <div className="box">
                  <div>
                    <i className="icon-ring" />
                  </div>
                  <Text className="benchmark-industry">
                    <b>{wording.jewelery}</b>
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={8} xl={8}>
                <div className="box">
                  <div>
                    <i className="icon-medical" />
                  </div>
                  <Text className="benchmark-industry">
                    <b>{wording.medical}</b>
                  </Text>
                </div>
              </Col>
              <Col xs={12} md={8} xl={8}>
                <div className="box">
                  <div>
                    <i className="icon-building" />
                  </div>
                  <Text className="benchmark-industry">
                    <b>{wording.other}</b>
                  </Text>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="content-certificated">
        <Title>{wording.certificated}</Title>
        <Row gutter={24}>
          <Col xs={12} md={5} xl={5}>
            <Image src={HOME_FBMP} preview={false} className="img-cert" />
          </Col>
          <Col xs={12} md={5} xl={5}>
            <Image src={HOME_GOMP} preview={false} className="img-cert" />
          </Col>
          <Col xs={12} md={5} xl={5}>
            <Image src={HOME_TIKTOKMP} preview={false} className="img-cert" />
          </Col>
          <Col xs={12} md={5} xl={5}>
            <Image src={HOME_SHOPIFYMP} preview={false} className="img-cert" />
          </Col>
        </Row>
      </div>
      <div className="content-testimoni" hidden>
        <Title>{wording.testimoni}</Title>
        <Image src={HOME_BREAK} preview={false} width={50} />
        <div className="section-carousel">
          <Carousel
            ref={(node) => {
              carousel = node;
            }}
            {...propsCarousel}
          >
            <div>
              <div className="content-carousel">
                <Title level={2}>
                  “If Batman has a utility belt, I have Thorium”
                </Title>
                <div className="description">
                  <Text>
                    Everything that I need to grow my brands is here. If there’s
                    trouble, opportunity, or new trends, I can quickly make
                    better directions.
                  </Text>
                </div>
                <Avatar size="large" src={ICON_THORIUM} />
                <Title level={3}>THORIUM</Title>
                <Text>founder, Thorium AI</Text>
              </div>
            </div>
            <div>
              <div className="content-carousel">
                <Title level={2}>
                  “If Batman has a utility belt, I have Thorium”
                </Title>
                <div className="description">
                  <Text>
                    Everything that I need to grow my brands is here. If there’s
                    trouble, opportunity, or new trends, I can quickly make
                    better directions.
                  </Text>
                </div>
                <Avatar size="large" src={ICON_THORIUM} />
                <Title level={3}>THORIUM</Title>
                <Text>founder, Thorium AI</Text>
              </div>
            </div>
          </Carousel>
          <div className="action-button-slide">
            <Button onClick={() => prevCarousel()}>
              <i className="icon-left" />
            </Button>
            <Button onClick={() => nextCarousel()}>
              <i className="icon-right" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionBottomContent.displayName = config.displayName;
SectionBottomContent.defaultProps = config.defaultProps;
SectionBottomContent.propTypes = config.propTypes;

export default SectionBottomContent;
