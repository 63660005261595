import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import emailjs from 'emailjs-com';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Image,
  notification,
} from 'antd';
import { LOGO_IMG_WHITE } from '../../../assets/images';

import config from './FooterLanding.component.config';
import './FooterLanding.component.style.less';
import './FooterLanding.component.style.responsive.less';

const { Title, Text, Link } = Typography;
const FooterLanding = () => {
  const { defaultLang } = useSelector((state) => state.header);
  const lang = defaultLang.toLowerCase();
  const wording = config.stringPage[lang];

  const form = useRef();
  const sendEmail = (formValue) => {
    emailjs
      .send(
        process.env.EMAILJS_SERVICEID,
        process.env.EMAILJS_TEMPLATEID,
        formValue,
        process.env.EMAILJS_USERID,
      )
      .then(
        () => {
          notification.success({
            message: 'Awesome!',
            description:
              'your message has been sent, please wait we will reply directly to your email.',
          });
          form.current.setFieldsValue({
            fullname: null,
            email: null,
            subject: null,
            message: null,
          });
        },
        (error) => {
          notification.error({
            message: 'Failure!',
            description: `your message not sent to us, ${error}`,
          });
        },
      );
  };
  return (
    <div id="footer-landing">
      <Row gutter={24}>
        <Col xs={24} md={12} xl={12}>
          <Title level={2}>{wording.contact}</Title>
          <Title level={3}>{wording.address}</Title>
          <div className="detail-address">
            <div>
              <Text>
                Lima Building, Jl. Sunda No.56-61, Kb. Pisang, Kec. Sumur
                Bandung, Kota Bandung, Jawa Barat 40112, Indonesia
              </Text>
            </div>
            <div>
              <Text>
                Jl. Kemang Raya Selatan VIII No.55, Jakarta Selatan, Indonesia
              </Text>
            </div>
            <div>
              <Text>
                <b>P.</b> +62818 0575 7585
              </Text>
            </div>
            <div>
              <Text>
                <b>E.</b> hi@thorium.ai
              </Text>
            </div>
          </div>
          <div className="detail-link">
            <div>
              <Link
                href="https://www.instagram.com/thorium.ai/"
                target="_blank"
              >
                <i className="icon-facebook" />
              </Link>
            </div>
            <div>
              <Link
                href="https://www.instagram.com/thorium.ai/"
                target="_blank"
              >
                <i className="icon-instagram" />
              </Link>
            </div>
            <div>
              <Link
                href="https://www.instagram.com/thorium.ai/"
                target="_blank"
              >
                <i className="icon-linkedin" />
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={24} md={12} xl={12}>
          <Title level={3}>{wording.contactMe}</Title>
          <div className="form-contact-us">
            <Form
              layout="vertical"
              autoComplete="off"
              ref={form}
              onFinish={sendEmail}
            >
              <Row gutter={24}>
                <Col xs={24} md={12} xl={12}>
                  <Form.Item
                    label={wording.fullname}
                    name="fullname"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Full Name!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={12}>
                  <Form.Item
                    label={wording.email}
                    name="email"
                    type="email"
                    rules={[
                      {
                        required: true,
                        message: 'Email is required',
                      },
                      {
                        type: 'email',
                        message: 'Email is not valid',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} xl={24}>
                  <Form.Item
                    label={wording.subject}
                    name="subject"
                    rules={[
                      { required: true, message: 'Please input your Subject!' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} xl={24}>
                  <Form.Item
                    label={wording.message}
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Your Message!',
                      },
                    ]}
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item wrapperCol={{ offset: 19 }}>
                <Button type="primary" htmlType="submit" size="large">
                  {wording.send}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      <div className="footer-indentity">
        <Row gutter={24}>
          <Col xs={24} md={8} xl={8}>
            <div className="foot-logos">
              <Image preview={false} src={LOGO_IMG_WHITE} />
            </div>
          </Col>
          <Col
            xs={24}
            md={8}
            xl={8}
            style={{ textAlign: 'center', color: '#FFFFFF99' }}
          >
            <Text style={{ textAlign: 'center', color: '#FFFFFF99' }}>
              © Copyright 2021 Thorium. All rights reserved.
            </Text>
          </Col>
          <Col xs={24} md={8} xl={8} style={{ textAlign: 'right' }}>
            <Text>
              <Link href="about">About Us</Link>
              {' | '}
              <Link href="analysis">Analysis</Link>
            </Text>
          </Col>
        </Row>
      </div>
    </div>
  );
};

FooterLanding.displayName = config.displayName;
FooterLanding.defaultProps = config.defaultProps;
FooterLanding.propTypes = config.propTypes;

export default FooterLanding;
