import React from 'react';
import { Col, Image, Row, Typography } from 'antd';
import {
  HOME_BANNER,
  HOME_BRODO,
  HOME_M231,
  HOME_COTTONINK,
  HOME_HMNS,
  HOME_ROUGHNECK,
  HOME_SCRMS,
} from '../../../assets/images/png';

import config from './SectionHeadContent.component.config';
import './SectionHeadContent.component.style.less';
import './SectionHeadContent.component.style.responsive.less';

const { Title, Text, Link } = Typography;
const SectionHeadContent = ({ ...props }) => {
  const lang = props.lang.toLowerCase();
  const wording = config.textPage[lang];
  return (
    <div id="SectionHeadContent">
      <div className="content-head">
        <Row gutter={50}>
          <Col xs={24} md={12} xl={12}>
            <div className="content-title">
              <Title>{wording.title}</Title>
              <div className="contet-title-sub">
                <Text className="description">{wording.desc}</Text>
              </div>
              <div>
                <Link
                  href="https://dashboard.thorium.ai/login"
                  className="ant-btn ant-btn-primary ant-btn-lg"
                >
                  {wording.take_control}
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} xl={12} style={{ textAlign: 'center' }}>
            <Image src={HOME_BANNER} preview={false} />
          </Col>
        </Row>
        <div className="content-case">
          <Title level={3}>{wording.case_study}</Title>
          <Row gutter={100}>
            <Col xs={12} md={4} xl={4}>
              <Image src={HOME_BRODO} preview={false} />
            </Col>
            <Col xs={12} md={4} xl={4}>
              <Image src={HOME_M231} preview={false} />
            </Col>
            <Col xs={12} md={4} xl={4}>
              <Image src={HOME_COTTONINK} preview={false} />
            </Col>
            <Col xs={12} md={4} xl={4}>
              <Image src={HOME_HMNS} preview={false} />
            </Col>
            <Col xs={12} md={4} xl={4}>
              <Image src={HOME_ROUGHNECK} preview={false} />
            </Col>
            <Col xs={12} md={4} xl={4}>
              <Image src={HOME_SCRMS} preview={false} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

SectionHeadContent.displayName = config.displayName;
SectionHeadContent.defaultProps = config.defaultProps;
SectionHeadContent.propTypes = config.propTypes;

export default SectionHeadContent;
