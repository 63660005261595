import PropTypes from 'prop-types';
import {
  TEXT_FOOT_CONTACT,
  TEXT_FOOT_ADDRESS,
  TEXT_FOOT_CONTACTTME,
  TEXT_FOOT_FULLNAME,
  TEXT_FOOT_EMAIL,
  TEXT_FOOT_SUBJECT,
  TEXT_FOOT_MESSAGE,
  TEXT_FOOT_SEND,
  TEXT_FOOT_CONTACT_IDN,
  TEXT_FOOT_ADDRESS_IDN,
  TEXT_FOOT_CONTACTTME_IDN,
  TEXT_FOOT_FULLNAME_IDN,
  TEXT_FOOT_EMAIL_IDN,
  TEXT_FOOT_SUBJECT_IDN,
  TEXT_FOOT_MESSAGE_IDN,
  TEXT_FOOT_SEND_IDN,
} from '../../../constants/string';

const displayName = 'FooterLanding';
const propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};
const defaultProps = {};

const stringPage = {
  eng: {
    contact: TEXT_FOOT_CONTACT,
    address: TEXT_FOOT_ADDRESS,
    contactMe: TEXT_FOOT_CONTACTTME,
    fullname: TEXT_FOOT_FULLNAME,
    email: TEXT_FOOT_EMAIL,
    subject: TEXT_FOOT_SUBJECT,
    message: TEXT_FOOT_MESSAGE,
    send: TEXT_FOOT_SEND,
  },
  idn: {
    contact: TEXT_FOOT_CONTACT_IDN,
    address: TEXT_FOOT_ADDRESS_IDN,
    contactMe: TEXT_FOOT_CONTACTTME_IDN,
    fullname: TEXT_FOOT_FULLNAME_IDN,
    email: TEXT_FOOT_EMAIL_IDN,
    subject: TEXT_FOOT_SUBJECT_IDN,
    message: TEXT_FOOT_MESSAGE_IDN,
    send: TEXT_FOOT_SEND_IDN,
  },
};

export default {
  displayName,
  propTypes,
  defaultProps,
  stringPage,
};
