import React from 'react';
import { Avatar, Col, Image, Row, Typography } from 'antd';
import { ICON_THORIUM } from '../../../assets/icons';
import {
  ANS_DESK,
  ANS_DASHBOARD,
  ANS_PLATFORM,
} from '../../../assets/images/png';

import config from './SectionSecondContent.component.config';
import './SectionSecondContent.component.style.less';
import './SectionSecondContent.component.style.responsive.less';

const { Title, Text } = Typography;
const SectionSecondContent = ({ ...props }) => {
  const lang = props.lang.toLowerCase();
  const wording = config.textPage[lang];
  return (
    <div id="SectionSecondContentAnalysis">
      <div className="content-first">
        <Row>
          <Col xs={24} md={14} xl={14}>
            <Image src={ANS_DESK} preview={false} />
          </Col>
          <Col xs={24} md={10} xl={10}>
            <div>
              <div className="content-description">
                <Title level={2}>{wording.realtime}</Title>
                <Title level={3}>{wording.realtime_sub}</Title>
                <Text className="description">{wording.realtime_desc}</Text>
              </div>
              <div className="content-testimoni" hidden>
                <Text>
                  “Before Thorium, I thought my business is doing well but its
                  not. Now I know exactly how we perform compared to the
                  industry.”
                </Text>
                <br />
                <br />
                <div className="testimoni">
                  <Avatar src={ICON_THORIUM} style={{ marginRight: 15 }} />
                  <b> Edwin Cullen, Founder of Vampiregoat</b>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content-second">
        <Title>{wording.insights}</Title>
        <div className="content-description">
          <Text>{wording.insights_desc}</Text>
        </div>
        <Image src={ANS_DASHBOARD} preview={false} />
      </div>
      <div className="content-third">
        <Title>{wording.allin}</Title>
        <div className="content-description">
          <Text>
            <b>{wording.allin_desc.split('::')[0]} </b>
            {wording.allin_desc.split('::')[1]}
          </Text>
        </div>
        <div className="content-third-integration">
          <Image src={ANS_PLATFORM} preview={false} />
        </div>
      </div>
    </div>
  );
};

SectionSecondContent.displayName = config.displayName;
SectionSecondContent.defaultProps = config.defaultProps;
SectionSecondContent.propTypes = config.propTypes;

export default SectionSecondContent;
