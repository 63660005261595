import React from 'react';
import animations from '../../utils/animations';
import { Landing } from '../../components/Layout';
import SectionHeadContent from './SectionHeadContent/SectionHeadContent.component';
import SectionMiddleContent from './SectionMiddleContent/SectionMiddleContent.component';
import SectionBottomContent from './SectionBottomContent/SectionBottomContent.component';

import config from './LandingIndex.component.config';
import './LandingIndex.component.style.less';

class LandingIndexPage extends React.PureComponent {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  render() {
    return (
      <Landing title="Home">
        <div style={{ animation: animations.popIn }}>
          <SectionHeadContent lang={this.props.defLang} />
          <SectionMiddleContent lang={this.props.defLang} />
          <SectionBottomContent lang={this.props.defLang} />
        </div>
      </Landing>
    );
  }
}

LandingIndexPage.displayName = config.displayName;
LandingIndexPage.defaultProps = config.defaultProps;
LandingIndexPage.propTypes = config.propTypes;

export default LandingIndexPage;
