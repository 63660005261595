import PropTypes from 'prop-types';

const displayName = 'Landing';
const propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
