import path from './path';

import LandingPage from '../containers/Landing/Landing.container';

export const BASE_ROUTE = [
  // {
  //   id: 'login',
  //   path: path.login,
  //   component: LoginPage,
  //   exact: true,
  // },
  {
    id: 'landing',
    path: path.base,
    component: LandingPage,
  },
];
