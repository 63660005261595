import React from 'react';
import animations from '../../utils/animations';

import SectionHeadContent from './SectionHeadContent/SectionHeadContent.component';
import SectionMiddleContent from './SectionMiddleContent/SectionMiddleContent.component';
import SectionBottomContent from './SectionBottomContent/SectionBottomContent.component';
import { Landing } from '../../components/Layout';

import config from './LandingAbout.component.config';
import './LandingAbout.component.style.less';

class LandingAboutPage extends React.PureComponent {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    return (
      <Landing title="Aboutus">
        <div style={{ animation: animations.popIn }}>
          <SectionHeadContent lang={this.props.defLang} />
          <SectionBottomContent lang={this.props.defLang} />
          <SectionMiddleContent lang={this.props.defLang} />
        </div>
      </Landing>
    );
  }
}

LandingAboutPage.displayName = config.displayName;
LandingAboutPage.defaultProps = config.defaultProps;
LandingAboutPage.propTypes = config.propTypes;

export default LandingAboutPage;
