import PropTypes from 'prop-types';

const displayName = 'Landing';
const propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};
const defaultProps = {};

export default { displayName, propTypes, defaultProps };
