// import PropTypes from 'prop-types';
import {
  TEXT_INSIGHTS_1_TITLE,
  TEXT_INSIGHTS_1_DESC,
  TEXT_INSIGHTS_2_SUM_DATA,
  TEXT_INSIGHTS_2_SUM_DATA_,
  TEXT_INSIGHTS_2_SALES_PERF,
  TEXT_INSIGHTS_2_SALES_PERF_,
  TEXT_INSIGHTS_2_CAMP_PERF,
  TEXT_INSIGHTS_2_CAMP_PERF_,
  TEXT_INSIGHTS_1_TITLE_IDN,
  TEXT_INSIGHTS_1_DESC_IDN,
  TEXT_INSIGHTS_2_SUM_DATA_IDN,
  TEXT_INSIGHTS_2_SUM_DATA__IDN,
  TEXT_INSIGHTS_2_SALES_PERF_IDN,
  TEXT_INSIGHTS_2_SALES_PERF__IDN,
  TEXT_INSIGHTS_2_CAMP_PERF_IDN,
  TEXT_INSIGHTS_2_CAMP_PERF__IDN,
  TEXT_BTN_CONTROL,
  TEXT_BTN_CONTROL_IDN,
} from '../../../constants/string';
const displayName = 'SectionHeadContent';
const propTypes = {};
const defaultProps = {};
const textPage = {
  eng: {
    title: TEXT_INSIGHTS_1_TITLE,
    description: TEXT_INSIGHTS_1_DESC,
    summary_data: TEXT_INSIGHTS_2_SUM_DATA,
    summary_data_desc: TEXT_INSIGHTS_2_SUM_DATA_,
    sales_perform: TEXT_INSIGHTS_2_SALES_PERF,
    sales_perform_desc: TEXT_INSIGHTS_2_SALES_PERF_,
    campaign_perform: TEXT_INSIGHTS_2_CAMP_PERF,
    campaign_perform_desc: TEXT_INSIGHTS_2_CAMP_PERF_,
    take_control: TEXT_BTN_CONTROL,
  },
  idn: {
    title: TEXT_INSIGHTS_1_TITLE_IDN,
    description: TEXT_INSIGHTS_1_DESC_IDN,
    summary_data: TEXT_INSIGHTS_2_SUM_DATA_IDN,
    summary_data_desc: TEXT_INSIGHTS_2_SUM_DATA__IDN,
    sales_perform: TEXT_INSIGHTS_2_SALES_PERF_IDN,
    sales_perform_desc: TEXT_INSIGHTS_2_SALES_PERF__IDN,
    campaign_perform: TEXT_INSIGHTS_2_CAMP_PERF_IDN,
    campaign_perform_desc: TEXT_INSIGHTS_2_CAMP_PERF__IDN,
    take_control: TEXT_BTN_CONTROL_IDN,
  },
};
export default { displayName, propTypes, defaultProps, textPage };
