import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import headerReducer from './header.reducer';

const headerPersistConfig = {
  key: 'header',
  storage,
};

const moduleState = {
  header: persistReducer(headerPersistConfig, headerReducer),
};

export default combineReducers(moduleState);
