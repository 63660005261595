import { SET_DEFAULT, SET_LANG } from '../types';

const initialState = {
  defaultReducer: {
    landing: 'landing',
  },
  defaultLang: 'ENG',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT:
      return {
        ...state,
        defaultReducer: {
          ...state.defaultReducer,
          ...action.payload,
        },
      };
    case SET_LANG:
      return {
        ...state,
        defaultLang: action.payload,
      };
    default:
      return state;
  }
};
