import PropTypes from 'prop-types';
import {
  TEXT_MENU_HOME,
  TEXT_MENU_HOME_IDN,
  TEXT_MENU_ABOUT,
  TEXT_MENU_ABOUT_IDN,
  TEXT_MENU_INSIGHTS,
  TEXT_MENU_INSIGHTS_IDN,
  TEXT_BTN_SIGNIN,
  TEXT_BTN_SIGNIN_IDN,
  TEXT_BTN_JOIN,
  TEXT_BTN_JOIN_IDN,
} from '../../../constants/string';

const displayName = 'HeaderMenuLanding';
const propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};
const defaultProps = {};
const stringPage = {
  eng: {
    home: TEXT_MENU_HOME,
    about: TEXT_MENU_ABOUT,
    insights: TEXT_MENU_INSIGHTS,
    signin: TEXT_BTN_SIGNIN,
    join: TEXT_BTN_JOIN,
  },
  idn: {
    home: TEXT_MENU_HOME_IDN,
    about: TEXT_MENU_ABOUT_IDN,
    insights: TEXT_MENU_INSIGHTS_IDN,
    signin: TEXT_BTN_SIGNIN_IDN,
    join: TEXT_BTN_JOIN_IDN,
  },
};

export default {
  displayName,
  propTypes,
  defaultProps,
  stringPage,
};
