import React from 'react';
import { Col, Image, Row, Typography } from 'antd';
import {
  ABOUT_DISCUSS,
  ABOUT_HUMAN1,
  ABOUT_HUMAN2,
  ABOUT_HUMAN3,
  ABOUT_HUMAN4,
} from '../../../assets/images/png';

import config from './SectionBottomContent.component.config';
import './SectionBottomContent.component.style.less';
import './SectionBottomContent.component.style.responsive.less';

const { Title, Text } = Typography;
const SectionBottomContent = ({ ...props }) => {
  const lang = props.lang.toLowerCase();
  const wording = config.textPage[lang];
  return (
    <div id="SectionBottomContentAbout">
      <div className="content-head">
        <Row gutter={50}>
          <Col xs={24} md={12} xl={12}>
            <Image src={ABOUT_DISCUSS} preview={false} />
          </Col>
          <Col xs={24} md={12} xl={12}>
            <div className="content-title">
              <Title>{wording.founder}</Title>
              <Title level={3}>{wording.founder_sub}</Title>
              <Text className="description">{wording.founder_desc}</Text>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content-bottom" hidden>
        <div className="content-head-title">
          <Title>{wording.founder_team}</Title>
          <div style={{ width: '40%', fontSize: 15, margin: 'auto' }}>
            <Text>{wording.founder_team_desc}</Text>
          </div>
        </div>
        <Row gutter={24}>
          <Col xs={24} md={6} xl={6}>
            <div>
              <Image src={ABOUT_HUMAN1} preview={false} />
              <Title level={3}>Edwin Cullen</Title>
              <div className="description">
                <Text>Chief Executive Officer • CEO</Text>
              </div>
            </div>
          </Col>
          <Col xs={24} md={6} xl={6}>
            <div>
              <Image src={ABOUT_HUMAN2} preview={false} />
              <Title level={3}>Edwin Cullen</Title>
              <div className="description">
                <Text>Chief Executive Officer • CEO</Text>
              </div>
            </div>
          </Col>
          <Col xs={24} md={6} xl={6}>
            <div>
              <Image src={ABOUT_HUMAN3} preview={false} />
              <Title level={3}>Edwin Cullen</Title>
              <div className="description">
                <Text>Chief Executive Officer • CEO</Text>
              </div>
            </div>
          </Col>
          <Col xs={24} md={6} xl={6}>
            <div>
              <div>
                <Image src={ABOUT_HUMAN4} preview={false} />
              </div>
              <Title level={3}>Edwin Cullen</Title>
              <div className="description">
                <Text>Chief Executive Officer • CEO</Text>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

SectionBottomContent.displayName = config.displayName;
SectionBottomContent.defaultProps = config.defaultProps;
SectionBottomContent.propTypes = config.propTypes;

export default SectionBottomContent;
