import React from 'react';

import animations from '../../utils/animations';
import SectionHeadContent from './SectionHeadContent/SectionHeadContent.component';
import SectionSecondContent from './SectionSecondContent/SectionSecondContent.component';
import { Landing } from '../../components/Layout';

import config from './LandingAnalysis.component.config';
import './LandingAnalysis.component.style.less';

class LandingAnalysisPage extends React.PureComponent {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    return (
      <Landing title="Insights">
        <div style={{ animation: animations.popIn }}>
          <SectionHeadContent lang={this.props.defLang} />
          <SectionSecondContent lang={this.props.defLang} />
        </div>
      </Landing>
    );
  }
}

LandingAnalysisPage.displayName = config.displayName;
LandingAnalysisPage.defaultProps = config.defaultProps;
LandingAnalysisPage.propTypes = config.propTypes;

export default LandingAnalysisPage;
