// import PropTypes from 'prop-types';
import {
  TEXT_ABOUT_2_TITLE,
  TEXT_ABOUT_2_SUBTITLE,
  TEXT_ABOUT_2_COMBINED_SALES,
  TEXT_ABOUT_2_COMBINED_SALES_,
  TEXT_ABOUT_2_INDUSTRY,
  TEXT_ABOUT_2_INDUSTRY_,
  TEXT_ABOUT_2_INTEGRATION,
  TEXT_ABOUT_2_INTEGRATION_,
  TEXT_ABOUT_2_REALTIME,
  TEXT_ABOUT_2_REALTIME_,
  TEXT_ABOUT_2_CASESTUDY,
  TEXT_ABOUT_2_CASESTUDY_,
  TEXT_ABOUT_2_TITLE_IDN,
  TEXT_ABOUT_2_SUBTITLE_IDN,
  TEXT_ABOUT_2_COMBINED_SALES_IDN,
  TEXT_ABOUT_2_COMBINED_SALES__IDN,
  TEXT_ABOUT_2_INDUSTRY_IDN,
  TEXT_ABOUT_2_INDUSTRY__IDN,
  TEXT_ABOUT_2_INTEGRATION_IDN,
  TEXT_ABOUT_2_INTEGRATION__IDN,
  TEXT_ABOUT_2_REALTIME_IDN,
  TEXT_ABOUT_2_REALTIME__IDN,
  TEXT_ABOUT_2_CASESTUDY_IDN,
  TEXT_ABOUT_2_CASESTUDY__IDN,
} from '../../../constants/string';
const displayName = 'SectionMiddleContent';
const propTypes = {};
const defaultProps = {};
const textPage = {
  eng: {
    title: TEXT_ABOUT_2_TITLE,
    subtitle: TEXT_ABOUT_2_SUBTITLE,
    combined_sales: TEXT_ABOUT_2_COMBINED_SALES,
    combined_sales_desc: TEXT_ABOUT_2_COMBINED_SALES_,
    industry: TEXT_ABOUT_2_INDUSTRY,
    industry_desc: TEXT_ABOUT_2_INDUSTRY_,
    integration: TEXT_ABOUT_2_INTEGRATION,
    integration_desc: TEXT_ABOUT_2_INTEGRATION_,
    realtime: TEXT_ABOUT_2_REALTIME,
    realtime_desc: TEXT_ABOUT_2_REALTIME_,
    casestudy: TEXT_ABOUT_2_CASESTUDY,
    casestudy_desc: TEXT_ABOUT_2_CASESTUDY_,
  },
  idn: {
    title: TEXT_ABOUT_2_TITLE_IDN,
    subtitle: TEXT_ABOUT_2_SUBTITLE_IDN,
    combined_sales: TEXT_ABOUT_2_COMBINED_SALES_IDN,
    combined_sales_desc: TEXT_ABOUT_2_COMBINED_SALES__IDN,
    industry: TEXT_ABOUT_2_INDUSTRY_IDN,
    industry_desc: TEXT_ABOUT_2_INDUSTRY__IDN,
    integration: TEXT_ABOUT_2_INTEGRATION_IDN,
    integration_desc: TEXT_ABOUT_2_INTEGRATION__IDN,
    realtime: TEXT_ABOUT_2_REALTIME_IDN,
    realtime_desc: TEXT_ABOUT_2_REALTIME__IDN,
    casestudy: TEXT_ABOUT_2_CASESTUDY_IDN,
    casestudy_desc: TEXT_ABOUT_2_CASESTUDY__IDN,
  },
};
export default { displayName, propTypes, defaultProps, textPage };
