import React from 'react';
import { Card, Col, Image, Row, Typography, Carousel, Button } from 'antd';
import {
  ICON_ARR_CLOCK,
  ICON_ARC_COMP,
  ICON_COMP_INT,
  ICON_CONT_DIGI,
} from '../../../assets/icons';
import {
  ABOUT_PR_BODYPACK,
  ABOUT_PR_BRODO,
  ABOUT_PR_NIION,
} from '../../../assets/images/png';

import config from './SectionMiddleContent.component.config';
import './SectionMiddleContent.component.style.less';
import './SectionMiddleContent.component.style.responsive.less';

const { Title, Text } = Typography;
const SectionMiddleContent = ({ ...props }) => {
  const lang = props.lang.toLowerCase();
  const wording = config.textPage[lang];
  let carousel = React.createRef();
  const propsCarousel = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
  };
  const nextCarousel = () => {
    carousel.next();
  };
  const prevCarousel = () => {
    carousel.prev();
  };
  return (
    <div id="SectionMiddleContentAbout">
      <div className="content-head">
        <div className="content-head-title">
          <Title>{wording.title}</Title>
          <div className="content-subtitle">
            <Text>{wording.subtitle}</Text>
          </div>
        </div>
        <Row gutter={30}>
          <Col xs={18} md={6} xl={6}>
            <Card>
              <Image src={ICON_CONT_DIGI} preview={false} width={70} />
              <Title level={3}>{wording.combined_sales}</Title>
              <div className="description">
                <Text>{wording.combined_sales_desc}</Text>
              </div>
            </Card>
          </Col>
          <Col xs={18} md={6} xl={6}>
            <Card>
              <Image src={ICON_COMP_INT} preview={false} width={70} />
              <Title level={3}>{wording.industry}</Title>
              <div className="description">
                <Text>{wording.industry_desc}</Text>
              </div>
            </Card>
          </Col>
          <Col xs={18} md={6} xl={6}>
            <Card>
              <Image src={ICON_ARC_COMP} preview={false} width={70} />
              <Title level={3}>{wording.integration}</Title>
              <div className="description">
                <Text>{wording.integration_desc}</Text>
              </div>
            </Card>
          </Col>
          <Col xs={18} md={6} xl={6}>
            <Card>
              <Image src={ICON_ARR_CLOCK} preview={false} width={70} />
              <Title level={3}>{wording.realtime}</Title>
              <div className="description">
                <Text>{wording.realtime_desc}</Text>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="content-middle" hidden>
        <div className="content-integration">
          <Row>
            <Col xs={24} md={8} xl={8}>
              <div className="description">
                <Title level={1}>{wording.casestudy}</Title>
                <Text>{wording.casestudy_desc}</Text>
              </div>
              <div className="action-button-slide">
                <Button onClick={() => prevCarousel()}>
                  <i className="icon-left" />
                </Button>
                <Button onClick={() => nextCarousel()}>
                  <i className="icon-right" />
                </Button>
              </div>
            </Col>
            <Col xs={24} md={16} xl={16}>
              <div className="section-carousel">
                <Carousel
                  ref={(node) => {
                    carousel = node;
                  }}
                  {...propsCarousel}
                >
                  <div>
                    <div className="content-carousel">
                      <Image src={ABOUT_PR_NIION} preview={false} />
                      <div className="description">
                        <Text>NIION</Text>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="content-carousel">
                      <Image src={ABOUT_PR_BRODO} preview={false} />
                      <div className="description">
                        <Text>BRODO</Text>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="content-carousel">
                      <Image src={ABOUT_PR_BODYPACK} preview={false} />
                      <div className="description">
                        <Text>BODYPACK</Text>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="content-carousel">
                      <Image src={ABOUT_PR_BODYPACK} preview={false} />
                      <div className="description">
                        <Text>BODYPACK</Text>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="content-carousel">
                      <Image src={ABOUT_PR_BODYPACK} preview={false} />
                      <div className="description">
                        <Text>BODYPACK</Text>
                      </div>
                    </div>
                  </div>
                </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

SectionMiddleContent.displayName = config.displayName;
SectionMiddleContent.defaultProps = config.defaultProps;
SectionMiddleContent.propTypes = config.propTypes;

export default SectionMiddleContent;
