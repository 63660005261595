// import PropTypes from 'prop-types';
import {
  TEXT_HOME_TITLE,
  TEXT_HOME_TITLE_IDN,
  TEXT_HOME_DESC,
  TEXT_HOME_DESC_IDN,
  TEXT_HOME_TITLE_STUDY,
  TEXT_HOME_TITLE_STUDY_IDN,
  TEXT_BTN_CONTROL,
  TEXT_BTN_CONTROL_IDN,
} from '../../../constants/string';
const displayName = 'SectionHeadContent';
const propTypes = {};
const defaultProps = {};
const textPage = {
  eng: {
    title: TEXT_HOME_TITLE,
    desc: TEXT_HOME_DESC,
    take_control: TEXT_BTN_CONTROL,
    case_study: TEXT_HOME_TITLE_STUDY,
  },
  idn: {
    title: TEXT_HOME_TITLE_IDN,
    desc: TEXT_HOME_DESC_IDN,
    take_control: TEXT_BTN_CONTROL_IDN,
    case_study: TEXT_HOME_TITLE_STUDY_IDN,
  },
};
export default { displayName, propTypes, defaultProps, textPage };
