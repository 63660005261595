import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Menu, Image, Typography, Dropdown, Button, Radio } from 'antd';
import SlidingPane from 'react-sliding-pane';
import { DownOutlined } from '@ant-design/icons';
import { LOGO_IMG } from '../../../assets/images';
import { ICON_CLOSE, ICON_MENU } from '../../../assets/icons';

import path from '../../../navigation/path';
import config from './HeaderMenuLanding.component.config';
import './HeaderMenuLanding.component.style.less';

const { Link } = Typography;
const HeaderMenuLanding = ({ location, history, setupLang }) => {
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const { defaultLang } = useSelector((state) => state.header);
  const lang = defaultLang.toLowerCase();
  const wording = config.stringPage[lang];
  const onNavigate = (e) => {
    if (e.key !== 'divider') history.replace(`/${e.key.slice(1)}`);
    setOpenSideMenu(false);
  };

  const handleMenuClick = (e) => {
    setupLang(e.key);
  };
  const menuLanguange = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="ENG">ENG</Menu.Item>
      <Menu.Item key="IDN">IDN</Menu.Item>
    </Menu>
  );

  const headerMenu = () => (
    <div id="header-landing">
      <div className="header-logo">
        <Image
          onClick={() => onNavigate({ key: '/' })}
          src={LOGO_IMG}
          preview={false}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <Menu
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
        onClick={onNavigate}
        mode="horizontal"
        className="header-menu"
      >
        <Menu.Item key="/">{wording.home}</Menu.Item>
        <Menu.Item key={path.LandingPath.about}>{wording.about}</Menu.Item>
        <Menu.Item key={path.LandingPath.insights}>
          {wording.insights}
        </Menu.Item>
      </Menu>
      <div className="header-button">
        <div>
          <Link
            href="https://dashboard.thorium.ai/login"
            className="ant-btn button-sign-in"
          >
            <span style={{ fontSize: 18 }}>{wording.signin}</span>
          </Link>
          <Link
            href="https://dashboard.thorium.ai/register"
            className="ant-btn ant-btn-primary button-join"
          >
            <span style={{ fontSize: 18 }}>{wording.join}</span>
          </Link>
        </div>
        <div>
          <Dropdown overlay={menuLanguange}>
            <Button>
              {defaultLang} <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );

  const handleMenuClickMobile = (e) => {
    setupLang(e.target.value);
  };
  const MenuLangMobile = () => (
    <Radio.Group
      options={[
        { label: 'ENG', value: 'ENG' },
        { label: 'IDN', value: 'IDN' },
      ]}
      onChange={handleMenuClickMobile}
      value={defaultLang}
      optionType="button"
      buttonStyle="solid"
    />
  );
  const renderContentMenuMobile = () => (
    <div>
      <div id="header-mobile-content">
        <div>
          <div
            className
            onClick={() => {
              setOpenSideMenu(false);
            }}
          >
            <img src={ICON_CLOSE} alt="icon-menu" />
          </div>
        </div>
        <div className="header-logo">
          <Image
            onClick={() => onNavigate({ key: '/' })}
            src={LOGO_IMG}
            preview={false}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      <div className="header-menu-mobile">
        <Menu
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={[location.pathname]}
          onClick={onNavigate}
          mode="vertical"
        >
          <Menu.Item key="/">{wording.home}</Menu.Item>
          <Menu.Item key={path.LandingPath.about}>{wording.about}</Menu.Item>
          <Menu.Item key={path.LandingPath.insights}>
            {wording.insights}
          </Menu.Item>
        </Menu>
      </div>
      <div className="header-button-mobile">
        <div style={{ textAlign: 'left' }}>{MenuLangMobile()}</div>
        <div>
          <Link
            href="https://dashboard.thorium.ai/register"
            className="ant-btn ant-btn-primary button-join"
          >
            <span style={{ fontSize: 18 }}>{wording.join}</span>
          </Link>
        </div>
        <div>
          <Link
            href="https://dashboard.thorium.ai/login"
            className="ant-btn button-sign-in"
          >
            <span style={{ fontSize: 18 }}>{wording.signin}</span>
          </Link>
        </div>
      </div>
    </div>
  );
  const headerMenuMobile = () => (
    <div id="header-mobile">
      <div>
        <div
          className=""
          onClick={() => {
            setOpenSideMenu(true);
          }}
        >
          <img src={ICON_MENU} alt="icon-menu" />
        </div>
      </div>
      <div className="header-logo">
        <Image
          onClick={() => onNavigate({ key: '/' })}
          src={LOGO_IMG}
          preview={false}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div>
        <Link
          href="https://dashboard.thorium.ai/register"
          className="ant-btn ant-btn-primary button-join"
        >
          <span style={{ fontSize: 18 }}>{wording.join}</span>
        </Link>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? headerMenuMobile() : headerMenu()}

      <SlidingPane
        className="sidebar-mobile"
        hideHeader
        width="100%"
        from="left"
        isOpen={openSideMenu}
        onRequestClose={() => {
          setOpenSideMenu(false);
        }}
      >
        {renderContentMenuMobile()}
      </SlidingPane>
    </>
  );
};

HeaderMenuLanding.displayName = config.displayName;
HeaderMenuLanding.defaultProps = config.defaultProps;
HeaderMenuLanding.propTypes = config.propTypes;

export default HeaderMenuLanding;
