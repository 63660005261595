import React from 'react';
import { Col, Image, Row, Typography } from 'antd';
import { ANS_BANNER } from '../../../assets/images/png';
import {
  ICON_COLL_DOC,
  ICON_BUS_GRA,
  ICON_ANO_INT,
} from '../../../assets/icons';

import config from './SectionHeadContent.component.config';
import './SectionHeadContent.component.style.less';
import './SectionHeadContent.component.style.responsive.less';

const { Title, Text, Link } = Typography;
const SectionHeadContent = ({ ...props }) => {
  const lang = props.lang.toLowerCase();
  const wording = config.textPage[lang];
  return (
    <div id="SectionHeadContentAnalysis">
      <div className="content-head">
        <Row gutter={24}>
          <Col xs={24} md={12} xl={12}>
            <div className="content-title">
              <Title>{wording.title}</Title>
              <Text className="description">
                <b>{wording.description.split('::')[0]} </b>
                {wording.description.split('::')[1]}
              </Text>
              <div>
                <Link
                  href="https://dashboard.thorium.ai/login"
                  className="ant-btn ant-btn-primary ant-btn-lg"
                >
                  {wording.take_control}
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} xl={12}>
            <Image src={ANS_BANNER} preview={false} />
          </Col>
        </Row>
        <div className="content-bottom">
          <Row gutter={24}>
            <Col xs={24} md={8} xl={8}>
              <div className="bottom-info">
                <Image src={ICON_COLL_DOC} preview={false} width={50} />
                <Title level={2}>{wording.summary_data}</Title>
                <Text className="description">{wording.summary_data_desc}</Text>
              </div>
            </Col>
            <Col xs={24} md={8} xl={8}>
              <div className="bottom-info">
                <Image src={ICON_BUS_GRA} preview={false} width={50} />
                <Title level={2}>{wording.sales_perform}</Title>
                <Text className="description">
                  {wording.sales_perform_desc}
                </Text>
              </div>
            </Col>
            <Col xs={24} md={8} xl={8}>
              <div className="bottom-info">
                <Image src={ICON_ANO_INT} preview={false} width={50} />
                <Title level={2}>{wording.campaign_perform}</Title>
                <Text className="description">
                  {wording.campaign_perform_desc}
                </Text>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

SectionHeadContent.displayName = config.displayName;
SectionHeadContent.defaultProps = config.defaultProps;
SectionHeadContent.propTypes = config.propTypes;

export default SectionHeadContent;
