// import PropTypes from 'prop-types';
import {
  TEXT_ABOUT_3_FOUNDER,
  TEXT_ABOUT_3_FOUNDER_SUB,
  TEXT_ABOUT_3_FOUNDER_DESC,
  TEXT_ABOUT_3_MEET_TEAM,
  TEXT_ABOUT_3_MEET_TEAM_DESC,
  TEXT_ABOUT_3_FOUNDER_IDN,
  TEXT_ABOUT_3_FOUNDER_SUB_IDN,
  TEXT_ABOUT_3_FOUNDER_DESC_IDN,
  TEXT_ABOUT_3_MEET_TEAM_IDN,
  TEXT_ABOUT_3_MEET_TEAM_DESC_IDN,
} from '../../../constants/string';
const displayName = 'SectionBottomContent';
const propTypes = {};
const defaultProps = {};
const textPage = {
  eng: {
    founder: TEXT_ABOUT_3_FOUNDER,
    founder_sub: TEXT_ABOUT_3_FOUNDER_SUB,
    founder_desc: TEXT_ABOUT_3_FOUNDER_DESC,
    founder_team: TEXT_ABOUT_3_MEET_TEAM,
    founder_team_desc: TEXT_ABOUT_3_MEET_TEAM_DESC,
  },
  idn: {
    founder: TEXT_ABOUT_3_FOUNDER_IDN,
    founder_sub: TEXT_ABOUT_3_FOUNDER_SUB_IDN,
    founder_desc: TEXT_ABOUT_3_FOUNDER_DESC_IDN,
    founder_team: TEXT_ABOUT_3_MEET_TEAM_IDN,
    founder_team_desc: TEXT_ABOUT_3_MEET_TEAM_DESC_IDN,
  },
};
export default { displayName, propTypes, defaultProps, textPage };
