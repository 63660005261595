import React from 'react';
import { Card, Col, Image, Row, Typography } from 'antd';
import {
  ICON_ARROW,
  ICON_NETWORK,
  ICON_AWARD,
  ICON_BUSINESS,
} from '../../../assets/icons';
import { HOME_INTEGRATION, HOME_SAVETIME } from '../../../assets/images/png';

import config from './SectionMiddleContent.component.config';
import './SectionMiddleContent.component.style.less';
import './SectionMiddleContent.component.style.responsive.less';

const { Title, Text } = Typography;
const SectionMiddleContent = ({ ...props }) => {
  const lang = props.lang.toLowerCase();
  const wording = config.textPage[lang];
  return (
    <div id="SectionMiddleContent">
      <div className="content-head">
        <div className="content-head-title">
          <Title>{wording.setup}</Title>
          <div className="content-head-title-sub">
            <Text>{wording.setup_sub}</Text>
          </div>
        </div>
        <Row gutter={30}>
          <Col xs={18} md={6} xl={6}>
            <Card>
              <Image src={ICON_ARROW} preview={false} width={70} />
              <Title level={3}>{wording.signup}</Title>
              <div className="description">
                <Text>{wording.signup_sub}</Text>
              </div>
            </Card>
          </Col>
          <Col xs={18} md={6} xl={6}>
            <Card>
              <Image src={ICON_NETWORK} preview={false} width={70} />
              <Title level={3}>{wording.connect}</Title>
              <div className="description">
                <Text>{wording.connect_sub}</Text>
              </div>
            </Card>
          </Col>
          <Col xs={18} md={6} xl={6}>
            <Card>
              <Image src={ICON_AWARD} preview={false} width={70} />
              <Title level={3}>{wording.check}</Title>
              <div className="description">
                <Text>{wording.check_sub}</Text>
              </div>
            </Card>
          </Col>
          <Col xs={18} md={6} xl={6}>
            <Card>
              <Image src={ICON_BUSINESS} preview={false} width={70} />
              <Title level={3}>{wording.bench}</Title>
              <div className="description">
                <Text>{wording.bench_sub}</Text>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="content-middle">
        <div className="content-middle-title">
          <Title>{wording.choose_thorium}</Title>
          <div className="content-head-title-sub">
            <Text>{wording.choose_sub}</Text>
          </div>
        </div>
        <div className="content-integration">
          <Row gutter={50}>
            <Col xs={24} md={12} xl={12} style={{ display: 'flex' }}>
              <div className="description">
                <Title level={2}>{wording.integrate}</Title>
                <Text>{wording.integrate_sub}</Text>
              </div>
            </Col>
            <Col xs={24} md={12} xl={12}>
              <Image src={HOME_INTEGRATION} preview={false} />
            </Col>
          </Row>
        </div>
        <div className="content-integration integration-sec">
          <Row gutter={50}>
            <Col xs={24} md={12} xl={12}>
              <Image src={HOME_SAVETIME} preview={false} />
            </Col>
            <Col xs={24} md={12} xl={12} style={{ display: 'flex' }}>
              <div className="description">
                <Title level={2}>{wording.saved}</Title>
                <Text>{wording.saved_sub}</Text>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

SectionMiddleContent.displayName = config.displayName;
SectionMiddleContent.defaultProps = config.defaultProps;
SectionMiddleContent.propTypes = config.propTypes;

export default SectionMiddleContent;
