import React from 'react';
import { Col, Image, Row, Typography } from 'antd';
import { ABOUT_BANNER } from '../../../assets/images/png';

import config from './SectionHeadContent.component.config';
import './SectionHeadContent.component.style.less';
import './SectionHeadContent.component.style.responsive.less';

const { Title, Text, Link } = Typography;
const SectionHeadContent = ({ ...props }) => {
  const lang = props.lang.toLowerCase();
  const wording = config.textPage[lang];
  return (
    <div id="SectionHeadContentAbout">
      <div className="content-head">
        <Row gutter={50}>
          <Col xs={24} md={12} xl={12}>
            <div className="content-title">
              <Title>{wording.title}</Title>
              <Text className="description">{wording.description}</Text>
              <div>
                <Link
                  href="https://dashboard.thorium.ai/login"
                  className="ant-btn ant-btn-primary ant-btn-lg"
                >
                  {wording.take_control}
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} xl={12}>
            <Image src={ABOUT_BANNER} preview={false} />
          </Col>
        </Row>
        <div className="content-bottom">
          <Row gutter={50}>
            <Col xs={24} md={12} xl={12}>
              <div className="bottom-title">
                <Title level={2}>{wording.second_title}</Title>
                <Text className="description">{wording.second_desc}</Text>
                <br />
                <br />
                <Text className="description">{wording.second_desc2}</Text>
              </div>
            </Col>
            <Col xs={24} md={12} xl={12}>
              <Row gutter={50}>
                <Col xs={24} md={12} xl={12}>
                  <div className="bottom-info">
                    <Title>200+</Title>
                    <Text>
                      <b>{wording.our_coustomer.split(',')[0]}</b>
                      {wording.our_coustomer.split(',')[1]}
                    </Text>
                  </div>
                </Col>
                <Col xs={24} md={12} xl={12}>
                  <div className="bottom-info">
                    <Title>85</Title>
                    <Text>
                      <b>{wording.employee.split(',')[0]}</b>
                      {wording.employee.split(',')[1]}
                    </Text>
                  </div>
                </Col>
                <Col xs={24} md={12} xl={12}>
                  <div className="bottom-info">
                    <Title>90%</Title>
                    <Text>
                      <b>{wording.our_customer2.split(',')[0]}</b>
                      {wording.our_customer2.split(',')[1]}
                    </Text>
                  </div>
                </Col>
                <Col xs={24} md={12} xl={12}>
                  <div className="bottom-info">
                    <Title>$50M</Title>
                    <Text>
                      <b>{wording.funding.split(',')[0]}</b>
                      {wording.funding.split(',')[1]}
                    </Text>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

SectionHeadContent.displayName = config.displayName;
SectionHeadContent.defaultProps = config.defaultProps;
SectionHeadContent.propTypes = config.propTypes;

export default SectionHeadContent;
