import React from 'react';
import { Layout } from 'antd';

import config from './Landing.component.config';
import './Landing.component.style.less';

const { Content } = Layout;
const Landing = ({ ...props }) => (
  <Layout id="landing">
    <Content className="landing-content">{props.children}</Content>
  </Layout>
);

Landing.displayName = config.displayName;
Landing.defaultProps = config.defaultProps;
Landing.propTypes = config.propTypes;

export default Landing;
