// import PropTypes from 'prop-types';
import {
  TEXT_HOME_4_TITLE,
  TEXT_HOME_4_SUB,
  TEXT_HOME_4_DESC,
  TEXT_HOME_4_APPAREL,
  TEXT_HOME_4_BEAUTY,
  TEXT_HOME_4_CONSUM,
  TEXT_HOME_4_FNB,
  TEXT_HOME_4_FINANCE,
  TEXT_HOME_4_GARDEN,
  TEXT_HOME_4_JEWEL,
  TEXT_HOME_4_MED,
  TEXT_HOME_4_OTHERS,
  TEXT_HOME_4_CERT,
  TEXT_HOME_4_TESTI,
  TEXT_HOME_4_TITLE_IDN,
  TEXT_HOME_4_SUB_IDN,
  TEXT_HOME_4_DESC_IDN,
  TEXT_HOME_4_APPAREL_IDN,
  TEXT_HOME_4_BEAUTY_IDN,
  TEXT_HOME_4_CONSUM_IDN,
  TEXT_HOME_4_FNB_IDN,
  TEXT_HOME_4_FINANCE_IDN,
  TEXT_HOME_4_GARDEN_IDN,
  TEXT_HOME_4_JEWEL_IDN,
  TEXT_HOME_4_MED_IDN,
  TEXT_HOME_4_OTHERS_IDN,
  TEXT_HOME_4_CERT_IDN,
  TEXT_HOME_4_TESTI_IDN,
} from '../../../constants/string';
const displayName = 'SectionBottomContent';
const propTypes = {};
const defaultProps = {};
const textPage = {
  eng: {
    title: TEXT_HOME_4_TITLE,
    subtitle: TEXT_HOME_4_SUB,
    description: TEXT_HOME_4_DESC,
    apparel: TEXT_HOME_4_APPAREL,
    beauty: TEXT_HOME_4_BEAUTY,
    consumer: TEXT_HOME_4_CONSUM,
    fnb: TEXT_HOME_4_FNB,
    finance: TEXT_HOME_4_FINANCE,
    garden: TEXT_HOME_4_GARDEN,
    jewelery: TEXT_HOME_4_JEWEL,
    medical: TEXT_HOME_4_MED,
    other: TEXT_HOME_4_OTHERS,
    certificated: TEXT_HOME_4_CERT,
    testimoni: TEXT_HOME_4_TESTI,
  },
  idn: {
    title: TEXT_HOME_4_TITLE_IDN,
    subtitle: TEXT_HOME_4_SUB_IDN,
    description: TEXT_HOME_4_DESC_IDN,
    apparel: TEXT_HOME_4_APPAREL_IDN,
    beauty: TEXT_HOME_4_BEAUTY_IDN,
    consumer: TEXT_HOME_4_CONSUM_IDN,
    fnb: TEXT_HOME_4_FNB_IDN,
    finance: TEXT_HOME_4_FINANCE_IDN,
    garden: TEXT_HOME_4_GARDEN_IDN,
    jewelery: TEXT_HOME_4_JEWEL_IDN,
    medical: TEXT_HOME_4_MED_IDN,
    other: TEXT_HOME_4_OTHERS_IDN,
    certificated: TEXT_HOME_4_CERT_IDN,
    testimoni: TEXT_HOME_4_TESTI_IDN,
  },
};
export default { displayName, propTypes, defaultProps, textPage };
