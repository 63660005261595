import path from './path';
import LandingIndexPage from '../containers/LandingIndex/LandingIndex.container';
import LandingAboutPage from '../containers/LandingAbout/LandingAbout.container';
import LandingAnalysisPage from '../containers/LandingAnalysis/LandingAnalysis.container';

export const LANDING_ROUTE = [
  {
    id: 'about',
    path: path.LandingPath.about,
    component: LandingAboutPage,
    exact: true,
  },
  {
    id: 'insights',
    path: path.LandingPath.insights,
    component: LandingAnalysisPage,
    exact: true,
  },
  {
    id: 'index',
    path: '/',
    component: LandingIndexPage,
  },
];
