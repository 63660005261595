import { connect } from 'react-redux';
import Landing from './Landing.component';

import { setupLang } from '../../redux/actions/language.actions';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  setupLang,
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
