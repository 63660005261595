// import PropTypes from 'prop-types';
import {
  TEXT_INSIGHTS_3_REALTIME,
  TEXT_INSIGHTS_3_REALTIME_SUB,
  TEXT_INSIGHTS_3_REALTIME_DESC,
  TEXT_INSIGHTS_4_INSIGHTS,
  TEXT_INSIGHTS_4_INSIGHTS_,
  TEXT_INSIGHTS_5_ALLIN,
  TEXT_INSIGHTS_5_ALLIN_,
  TEXT_INSIGHTS_3_REALTIME_IDN,
  TEXT_INSIGHTS_3_REALTIME_SUB_IDN,
  TEXT_INSIGHTS_3_REALTIME_DESC_IDN,
  TEXT_INSIGHTS_4_INSIGHTS_IDN,
  TEXT_INSIGHTS_4_INSIGHTS__IDN,
  TEXT_INSIGHTS_5_ALLIN_IDN,
  TEXT_INSIGHTS_5_ALLIN__IDN,
} from '../../../constants/string';
const displayName = 'SectionSecondContent';
const propTypes = {};
const defaultProps = {};
const textPage = {
  eng: {
    realtime: TEXT_INSIGHTS_3_REALTIME,
    realtime_sub: TEXT_INSIGHTS_3_REALTIME_SUB,
    realtime_desc: TEXT_INSIGHTS_3_REALTIME_DESC,
    insights: TEXT_INSIGHTS_4_INSIGHTS,
    insights_desc: TEXT_INSIGHTS_4_INSIGHTS_,
    allin: TEXT_INSIGHTS_5_ALLIN,
    allin_desc: TEXT_INSIGHTS_5_ALLIN_,
  },
  idn: {
    realtime: TEXT_INSIGHTS_3_REALTIME_IDN,
    realtime_sub: TEXT_INSIGHTS_3_REALTIME_SUB_IDN,
    realtime_desc: TEXT_INSIGHTS_3_REALTIME_DESC_IDN,
    insights: TEXT_INSIGHTS_4_INSIGHTS_IDN,
    insights_desc: TEXT_INSIGHTS_4_INSIGHTS__IDN,
    allin: TEXT_INSIGHTS_5_ALLIN_IDN,
    allin_desc: TEXT_INSIGHTS_5_ALLIN__IDN,
  },
};
export default { displayName, propTypes, defaultProps, textPage };
